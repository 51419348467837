<template>
  <main>
    <section class="privacyPolicy_tittle">
      <h1>プライバシーポリシー</h1>
    </section>
    <section class="privacyPolicy">
      <div class="privacyPolicy_inner">
        <p class="privacyPolicy_bottom2em">
          株式会社JustFit(以下「JustFit」といいます)は、以下のとおりプライバシーポリシーを定め、
          プライバシーポリシーはJustFitの提供する全てのサービスに適用されるものとします。
        </p>
        <p class="privacyPolicy_bottom1em">
          JustFitは利用者の皆様のプライバシー保護に最大限の注意を払います。
          JustFitが利用者の皆様から取得した個人情報は､プライバシーポリシーに従って取り扱われます。
          なお､JustFitのプライバシーポリシーは､広告を掲載している広告主等が利用者の皆様から取得する
          個人情報については適用されず、また､JustFitの利用者以外の方には適用されません。
        </p>
        <p>
          JustFitは､利用者の皆様がサービスをご利用になる際に、利用者の皆様に関する情報を自動的に取得することがあります。
          また､JustFitにお電話等でお問い合わせをいただく際に個人情報をお尋ねする場合もあります。
          JustFitが利用者の皆様から取得する個人情報は､サービスを提供するために必要なものに限定されています。
          JustFitは利用者の皆様のＩＰアドレス､ご覧になった広告やページ､ご利用環境等の情報を利用者の皆様の
          ブラウザから自動的に受け取り､サーバーに記録することがあります。
        </p>
        <p class="privacyPolicy_bottom1em">
          JustFitは、取得した情報を以下の目的で利用します。
        </p>
        <ul>
          <li>サービスを利用者の皆様に提供するために利用します。</li>
          <li>
            利用者の皆様が目にする広告等をそれぞれの利用者向けに最適化する等、
            広告･情報の媒体としての利便性を向上させるため､性別､年齢､住所､これまでにご利用いただいたサービス､
            ご覧になった広告やページ､ご利用時間帯､ご利用の方法､ご利用環境等の利用者属性に関する情報等
            (以下｢利用者属性に関する情報｣といいます)を利用する場合があります。
          </li>
          <li>
            提供しているサービスや広告の内容を､より充実させたり､新しいサービスを検討したりするために､
            利用者属性に関する情報を利用する場合があります。
          </li>
          <li>
            広告主等に､どのような広告や情報､サービス等を掲載または提供していただくことが
            最適かつ効果的であるかを分析して提供するために､利用者属性に関する情報を分析する等して利用する場合があります。
            なお､個人が特定されるような情報が分析結果に含まれることはありません。
          </li>
          <li>利用者の皆様にサービスに関するお知らせをさせていただくために､メールアドレス等の情報を利用する場合があります。</li>
          <li>利用者の皆様の本人確認を行う際､氏名､年齢､性別､住所等の情報を利用する場合があります。</li>
          <li>
            第三者に損害を発生させたりする等､利用規約に違反した方や､不正･不当な目的でサービスを
            利用される方のご利用をお断りするために､ご利用態様､氏名や住所等個人を特定するための情報を利用する場合があります。
          </li>
          <li>
            利用者の皆様からのお問い合わせに対応するために､お問い合わせ内容､利用者属性に関する情報等といった
            お問い合わせいただいたサービスを提供するために利用している情報等を利用する場合があります。
          </li>
          <li>
            JustFitが行う利用動向調査に代表されるマーケティングリサーチ等の事業に活用するため､
            利用者の皆様が提供される情報を利用する場合があります。<br>
            個人が特定されない形式に情報を加工したうえで､<br>
            JustFitが行う出版､情報配信､マーケティングその他のサービスに活用するため､情報を利用する場合があります。
          </li>
          <li>サービスの解約や解約後の事後処理を行うため､利用者の皆様がJustFitに提供する情報を利用する場合があります。</li>
        </ul>
        <p class="privacyPolicy_bottom1em">
          JustFitは､個人情報を販売したり貸し出したりすることはいたしません。<br>
          以下の場合に､JustFitは個人情報を第三者に提供することがあります。
        </p>
        <ul>
          <li>情報提供についてご本人様の同意がある場合</li>
          <li>
            裁判所から､特定電気通信役務提供者の損害賠償責任の制限および発信者情報の開示に関する法律
            (平成十三年十一月三十日法律第百三十七号)に基づく開示を命じる判決もしくは命令を受けた場合､
            または､警察等の公的機関から､刑事訴訟法(昭和二十三年七月十日法律第百三十一号)等の
            捜査権限を定める法令に基づき正式な照会を受けた場合
          </li>
          <li>
            サービスの利用に関連して､利用者が法令や利用規約､ガイドライン等に反し､
            第三者またはJustFitの権利､財産､サービス等を保護するために必要と認められる場合
          </li>
          <li>人の生命､身体および財産等に対する差し迫った危険があり、本人の同意を得ることが困難で､緊急の必要性がある場合</li>
        </ul>
        <p class="privacyPolicy_bottom1em">
          JustFitは、クッキーを利用者の皆様のコンピュータに保存し、参照することがあります。<br>
          JustFitのページで広告またはコンテンツを表示している会社が、利用者の皆様のコンピュータ内にクッキーを保存し参照することがあります。
          この場合、これらの第三者によるクッキーの利用は、その第三者のプライバシーポリシーに従って行われます。
          広告主やその他の会社が、JustFitのクッキーを参照することはできません。
          JustFitは、JustFitから配信された広告を表示する他社サイト上で、利用者の皆様のコンピュータ内にクッキーを保存し、
          参照することがあります。この場合、クッキーは、他社に提供・開示されることはなく、
          JustFitのプライバシーポリシーに従って利用されます。
        </p>
        <p class="privacyPolicy_bottom1em">
          JustFitは､利用者ご本人様より個人情報の開示､訂正､利用停止､削除のご依頼があった場合､
          ご本人様であることを確認させていただいた上で､対応させていただきます。<br>
          JustFitは､個人情報およびそれらを処理する情報システムを安全に取り扱っています。
        </p>
        <p class="privacyPolicy_bottom1em">
          利用者の皆様の登録情報は利用者の皆様のプライバシー保護およびセキュリティのため､パスワードで保護されています。
        </p>
        <p class="privacyPolicy_bottom1em">
          利用者の皆様にサービスを提供する目的で､JustFitが第三者に業務を委託する場合には､
          業務委託先の管理を適切に実施するために契約を締結します。
        </p>
        <p>
          今後､JustFitはプライバシーポリシーの全部または一部を改訂することがあります。&nbsp;
          改訂があった場合には、JustFitに掲載された時点で､すべての利用者の皆様に自動的に適用されます。
        </p>
        <p class="privacyPolicy_bottom2em">
          今後､JustFitはプライバシーポリシーの全部または一部を改訂することがあります。&nbsp;
          改訂があった場合には、JustFitに掲載された時点で､すべての利用者の皆様に自動的に適用されます。
        </p>
        <p class="terms_enactment">
          2020年3月&nbsp;&nbsp;制定・施行
        </p>
      </div>
    </section>
  </main>
</template>
<script>
export default {
  name: 'PrivacyPolicy'
}
</script>
<style scoped>
main{
  font-family: "YuGothic";
}
h1{
  font-size: 21px;
  font-weight: bold;
}
p, li{
  font-size: 14px;
}
ul{
  list-style-position: inside;
}
.privacyPolicy_tittle{
  padding: 0 40px;
  background-color: #ffffff;
  text-align: center;
}
.privacyPolicy_tittle h1{
  padding: 40px;
}
.privacyPolicy{
  padding: 0 40px;
  background-color:#F5EAE7;
}
.privacyPolicy_inner{
  padding:54px 0 67px 0;
}
.privacyPolicy_bottom2em{
  padding-bottom: 2em;
}
.privacyPolicy_bottom1em{
  padding-bottom: 1em;
}
.privacyPolicy_inner ul{
  padding-bottom: 2em;
  list-style-type: disc;
  list-style-position: inside;
  text-indent: -1em;
  padding-left: 1em;
}
.terms_enactment{
  display: block;
  text-align: right;
}
</style>
